* {
  font-family: "Roboto", sans-serif;
  background-color: var(--main-bg);
  scroll-behavior: smooth;
}

:root {
  --dark-bg: #222730;
  --light-bg: #1f2833;
  --light-alt-bg: #324052;
  --regular-text: #c5c6c7;
  --bright-text: #b8e9f1;
  --regular-color: #94bdce;
  scroll-behavior: smooth;
}

.bg-light {
  background-color: var(--light-bg);
}

.bg-normal {
  background-color: var(--light-bg);
}

.bg-dark {
  background-color: var(--dark-bg);
}

/* Used to bring elements above particlesjs */
.appear-top {
  position: relative;
  z-index: 1;
}

/* Global container classes */
.center-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.center-container-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center {
  margin: auto;
}

.btn-primary {
  background-color: var(--dark-bg);
  border-color: #b8e9f1;
  color: #b8e9f1;
}
.btn-primary:hover {
  background-color: var(--light-bg);
  border-color: #c5c6c7;
  color: whitesmoke;
  transition: 0.1s;
}
.btn-secondary {
  background-color: var(--dark-bg);
  border-color: var(--regular-color);
  color: var(--regular-color);
  fill: var(--regular-color);
  transition: 0s;
}
.btn-secondary:hover {
  background-color: var(--dark-bg);
  border-color: var(--regular-color);
  color: var(--bright-text);
  fill: var(--bright-text);
  transition: 0s;
}