/* NAVBAR*/
header {
  border-bottom: 1px solid whitesmoke;
  position: relative;
  z-index: 1;
}
  
#resume-btn {
  background-color: var(--light-bg);
  border: 2px solid var(--regular-color);
  margin-left: 10px;
  padding: 6px 12px;
  color: var(--bright-text);
  width: 86px;
}
#resume-btn:hover {
  border: 2px solid #fff;
  box-shadow: 0 0 10px 1px #4de4ff, 0 0 10px 1px #4de4ff;  
  transition: 0.2s;
}
.nav-item {
  margin-left: 10px;
  text-align: center;
}

.remove-border {
  border: 0;
}
  
.logo {
  height: 42px;
  width: 42px;
  color: var(--bright-text);
  background-color: var(--regular-color);
  margin: 4px;
  border: 2px solid #ffffff;
  border-radius: 5px;
  font-size: 32px;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* MEDIA QUERIES */
@media screen and (max-width: 375px) {
  .navbar-nav {
    align-items: center;
  }
  
}
@media screen and (min-width: 375px) {
  .navbar-nav {
    align-items: center;
  }
}
@media screen and (min-width: 575px) {

}
@media screen and (min-width: 1024px) {

}