.codepen-style {
        height: "450px"; 
        box-sizing: "border-box"; 
        display: "flex"; 
        align-items: "center"; 
        justify-content: "center"; 
        border: "2px solid"; 
        margin: "1em 0"; 
        padding: "1em";
}

.code-sample {
        position: relative;
}

.code-sample iframe {
        width: 100%;
        height: 450px;
        z-index: 1;
}

.code-sample h2, h3 {
        color: whitesmoke;
        z-index: 1;

}

.code-sample p {
        color: whitesmoke;
        z-index: 1;
        
}