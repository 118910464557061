footer {
  color: whitesmoke;
  position: relative;
  width: 100%;
  white-space: nowrap;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-top: 1px solid whitesmoke;
  overflow: hidden;
}