:root {
  --glow-effect: 1;
}
.hero {
  padding-top: 10vh;
  margin: 0px auto;
}
.hero h1 {
  font-size: 3em;
}
.hero h3 {
  color: var(--regular-color);
  font-size: 1em; 
}
.profile-pic {
  width: 140px;
  height: 140px;
  position: relative;
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 42px;
}


/* The "call to action button on hero section */
.btn-view-projects {
    border: 3px solid whitesmoke;
    color: whitesmoke;
    padding: 10px 20px;
    padding-left: 24px;
    margin: 20px auto;
}
.btn-view-projects:hover {
  background-color: var(--regular-color);
  color: black;
  transition: 0.3s;
}

/* Down arrow/chevron */
.view-code-sample {
  position: absolute;
  bottom: 10vh;
  text-decoration: none;
  text-align: center;
}
.view-code-sample img {
  height: 75px;
  width: 75px;
  filter: brightness(1000%);
  
}
.view-code-sample img:hover {
  filter: hue-rotate(430deg);
}

.view-code-sample p {
  margin-bottom: 0;
}

/* Social Links section */
.social-links {
  padding: 40px 0;
  color: var(--regular-color);
  text-align: center;
}
.social-links h2 {
  margin-bottom: 20px;
}
.social-links a {
  color: whitesmoke;
}

/* Glow effects */
.glow {
  font-size: 24px;
  color: var(--bright-text);
  text-align: center;
  /* -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate; */
}

@keyframes glow {
  from {
    text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #00a5e6, 0 0 8px #00c4e6, 0 0 10px #00b8e6, 0 0 12px #00cbe6, 0 0 14px #00dee6;
  }
  to {
    text-shadow: 0 0 4px #fff, 0 0 6px #4de4ff, 0 0 8px #4de4ff, 0 0 10px #4deaff, 0 0 12px #4dd5ff, 0 0 14px #4df3ff, 0 0 16px #4de4ff;
  }
}

/* MEDIA QUERIES */
@media screen and (max-width: 375px) {
  .profile-pic {
    width: 80px;
    height: 80px;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
}
@media screen and (min-width: 375px) {
  .about-me {
    height: 800px;
  }
  .profile-pic {
    width: 140px;
    height: 140px;
  }
}
@media screen and (min-width: 1024px) {
  .about-me {
    height: 1000px;
  }
}

/* Height - MEDIA QUERY */
@media screen and (max-height: 720px) {
  .hero h1 {
    font-size: 3em;
  }
  .hero h3 {
    font-size: 1em;
  }
  .view-code-sample {
    display: none;
  }
}
@media screen and (min-height: 720px) {
  .hero h1 {
    font-size: 5em;
  }
  .hero h3 {
    font-size: 1.9em;
  }
}