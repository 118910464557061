
.about-title {
  color: var(--bright-text);
  margin-bottom: 10px;

}
.about-desc {
  color: var(--regular-text);
  padding-left: 12px;
}
.location-tag {
  display: flex;
  justify-content: center;
  color: var(--bright-text);
  margin-bottom: 40px;
}
.location-tag img {
  height: 24px;
}
.strong {
  color: whitesmoke
}

/* Media queries (smallest -> largest) */
@media screen and (max-width: 375px) {
  .about-section {
    padding: 10px;
  }
}
@media screen and (min-width: 375px) {
  .about-section {
    padding: 10px;
  }
}
@media screen and (min-width: 575px) {
  .about-section {
    padding: 60px 20% 10px;
  }
  
}